import {Slot} from '@radix-ui/react-slot';
import {AnimatePresence, motion} from 'framer-motion';
import type {ReactNode} from 'react';
import {createContext, useContext, useEffect, useState} from 'react';
type ViewProps = {
  children?: ReactNode;
  className?: string;
};

export const View = ({children, className}: ViewProps) => {
  const {isFirstRender} = useContext(ViewContext);
  return (
    <AnimatePresence>
      <motion.div
        initial={isFirstRender ? {opacity: 1, y: 0} : {opacity: 0, y: 10}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: -10}}
        transition={{duration: 0.5, ease: 'easeInOut'}}>
        <Slot className={className}>{children}</Slot>
      </motion.div>
    </AnimatePresence>
  );
};

type ViewContextValue = {
  isFirstRender: boolean;
};
const ViewContext = createContext({isFirstRender: false} as ViewContextValue);

type ViewProviderProps = {
  children?: ReactNode;
};
export const ViewProvider = ({children}: ViewProviderProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return <ViewContext.Provider value={{isFirstRender}}>{children}</ViewContext.Provider>;
};
